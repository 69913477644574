import React, { useRef, useState } from "react";
import ImageLogin from "../../../assets/images/gif/01.gif";
import "../Login.css";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import ImageLoginForm from "../ImageLogin/ImageLogin";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import LoginBottomInfo from "./LoginBottomInfo";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";
import LogoImageForm from "../LogoForm/LogoImageForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../../axios";
import { useDispatch } from "react-redux";
import { login } from "../../../Redux/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import audio from "../../../assets/WELCOME.mp3";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "Components/Languages/LanguageSwitcher";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("ايميل خطاء").required("هذا الحقل مطلوب"),
});

const initialValues = {
  email: "",
};

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const soundEffect = useRef();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    setLoading(true);
    console.log(values);
    axiosInstance
      .post("/otpweb", values)
      .then((res) => {
        console.log(res);

        setLoading(false);
        toast.success("تم ارسال الكود الى البريد الالكتروني");
        router("/otp", {
          state: {
            email: values.email,
            otp: res.data.otp,
          },
        });

        resetForm();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          toast.error(" البريد الالكتروني  خطأ");

          setLoading(false);
        } else {
          toast.error("حدث خطأ ما");

          setLoading(false);
        }
      });
  };
  const { t } = useTranslation();
  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <HelmetInfo titlePage={"تسجيل الدخول"} />
      <audio ref={soundEffect} src={audio}></audio>
      <div className="login-form">
        {/* ========= START LOGO LOGIN ======== */}
        <div className="logo-top">
          <LogoImageForm />
        </div>
        {/* ========= END LOGO LOGIN ======== */}
        {/* ========= START CONTAINER =========== */}
        <div className="container-fluid">
          {/* ======== START ALL LOGIN FORM =========== */}
          <div className="all-Login-form">
            {/* ========== START ROW =========== */}
            <div className="row g-3 align-items-center">
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO FORM LOGIN =========== */}
                <div className="info-form-login position-relative">
                  {/* ========= START ICON CLOSE ========= */}
                  <div className="formheadernewx01">
                    <IconHeaderForm routerPage={"/"} iconHeader={faTimes} />
                    <LanguageSwitcher />
                  </div>
                  {/* ========= END ICON CLOSE ========= */}
                  {/* ========= START ALL FORM LOG ======== */}
                  <div className="all-form-log">
                    {/* ========== START HEADER FORM LOG ========= */}
                    <HeaderForm
                      titleHeaderForm={t("changePassword") + " !"}
                      textHeaderForm={t("chagePasswordtest")}
                    />
                    {/* ========== END HEADER FORM LOG ========= */}

                    {/* ========== START FORM FIELD ========= */}
                    <FormField
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <InputFiled
                        label={t("email")}
                        name="email"
                        type="email"
                        placeholder=""
                        success
                      />

                      <button
                        type="submit"
                        className="btn btn-main btn-submit w-100 mt-5"
                      >
                        {loading ? (
                          <span className="loader"></span>
                        ) : (
                          t("continue")
                        )}
                      </button>
                    </FormField>
                    {/* ========== END FORM FIELD ========= */}
                    {/* ========== START BOTTOM FORM ========= */}
                    <LoginBottomInfo />
                    {/* ========== END BOTTOM FORM ========= */}
                  </div>
                  {/* ========= END ALL FORM LOG ======== */}
                </div>
                {/* ========== END INFO FORM LOGIN =========== */}
              </div>
              {/* =========== END COL ======== */}
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO LOGIN ========== */}
                <div className="info-login position-relative">
                  {/* ========= START IMAGE LOGIN ======= */}
                  <ImageLoginForm ImageLoginPic={ImageLogin} />
                  {/* ========= END IMAGE LOGIN ======= */}
                  {/* ========= START LOGO LOGIN ======== *
                 <div className="logo-login-bottom">
                   <LogoImageForm />
                 </div>
                 {/* ========= END LOGO LOGIN ======== */}
                </div>
                {/* ========== END INFO LOGIN ========== */}
              </div>
              {/* =========== END COL ======== */}
            </div>
            {/* ========== END ROW =========== */}
          </div>
          {/* ======== END ALL LOGIN FORM =========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </div>
    </>
  );
};

export default ForgetPassword;
