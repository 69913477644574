// import CustomModal from "Components/CustomModal/CustomModal";
// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";
// import axiosInstance from "../../../axios";

// const ExtraSubscribeModal = ({
//   ShowModal,
//   handleCloseModalEditWork,
//   price,
//   HandleAmount,
// }) => {
//   const [loading, setLoading] = useState(false);
//   const { t, i18n } = useTranslation();
//   const [activeTab, setActiveTab] = useState("wallet");

//   const handleExtraSubscribe = () => {
//     setLoading(true);
//     if (activeTab === "wallet") {
//       axiosInstance
//         .post(
//           "/extrasubscription",
//           {
//             package_id: ShowModal.id,
//             payment_method: activeTab,
//             transaction_id: "your_transaction_id_here", // Replace with actual transaction_id if available
//           },
//           {
//             headers: {
//               "Accept-Language": i18n.language,
//             },
//           }
//         )
//         .then((res) => {
//           toast.success(t("Subscribed successfully"));
//           setLoading(false);
//           handleCloseModalEditWork();
//         })
//         .catch((error) => {
//           toast.error(error.response?.data?.message || t("Error subscribing"));
//           setLoading(false);
//         });
//     } else {
//       HandleAmount(price, setLoading);
//     }
//   };

//   return (
//     <CustomModal
//       show={ShowModal}
//       onHide={handleCloseModalEditWork}
//       title={t("Package Subscription")}
//       newClass={"modal-account modal-account-tabs"}
//     >
//       <div className="form-upload">
//         <h2 style={{ fontSize: "24px", paddingInlineStart: "10px" }}>
//           {t("Payment Method")}
//         </h2>
//         <div className="row mb-3">
//           <div className="col-6">
//             <div
//               onClick={() => setActiveTab("wallet")}
//               className={`PaymentMethod ${activeTab === "wallet" && "active"}`}
//             >
//               {t("Wallet")}
//             </div>
//           </div>
//           <div className="col-6">
//             <div
//               onClick={() => setActiveTab("stripe")}
//               className={`PaymentMethod ${activeTab === "stripe" && "active"}`}
//             >
//               {t("Visa")}
//             </div>
//           </div>
//         </div>
//         <div>
//           <div className="d-flex gap-2 dataRowSub">
//             <label>{t("package Name")}</label>: {ShowModal?.title}
//           </div>
//           <div className="d-flex gap-2 dataRowSub">
//             <label>{t("package Price")}</label>: {price}
//           </div>
//         </div>
//         <div className="pt-1 pb-1 font-18 d-flex align-content-center">
//           تجديد الاشتراك تلقائيا
//           <input
//             type="checkbox"
//             className="m-1"
//             style={{ width: "18px", height: "18px" }}
//           />
//         </div>
//         <button
//           onClick={handleExtraSubscribe}
//           disabled={loading}
//           className="submit-upload w-100 btn-main"
//         >
//           {loading ? <span className="loader"></span> : t("next")}
//         </button>
//       </div>
//     </CustomModal>
//   );
// };

// export default ExtraSubscribeModal;

import CustomModal from "Components/CustomModal/CustomModal";
import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import styles from "./extrasubscrive.module.css";
import PaymentSuccessLottie from "Components/LandingPageComponent/PaymentSuccessLotties/PaymentSuccessLottie";

const ExtraSubscriptionModal = ({
  ShowModal,
  handleCloseModalEditWork,
  price,
  HandleAmount,
}) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("wallet");
  const [paymentsuccessLottiex, setpaymentsuccessLottie] = useState(false);
  const handleSubscribe = async () => {
    setLoading(true);
    try {
      let transaction_id = null;

      if (activeTab === "stripe") {
        // تنفيذ الدفع باستخدام Stripe والحصول على transaction_id
        const stripeResponse = await HandleAmount(price, setLoading);
        transaction_id = stripeResponse.transaction_id;
      }

      await axiosInstance.post(
        "/extrasubscription",
        {
          package_id: ShowModal.id,
          payment_method: activeTab,
          transaction_id: transaction_id, // قيمة transaction_id بعد عملية Stripe
        },
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      );

      toast.success(t("Subscribed successfully"));
      setpaymentsuccessLottie(true);

      setLoading(false);
      setTimeout(() => {
        setpaymentsuccessLottie(false);
        handleCloseModalEditWork();
      }, 2000);
    } catch (error) {
      // toast.error(error.response?.data?.message || t("Subscription failed"));
      // toast.error("iam here ya jhon");
      setLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        show={ShowModal}
        onHide={handleCloseModalEditWork}
        title={t("Extra Subscription")}
        newClass={"modal-account modal-account-tabs"}
      >
        <div className="form-upload">
          <h2 style={{ fontSize: "24px", paddingInlineStart: "10px" }}>
            {t("Payment Method")}
          </h2>
          <div className="row mb-3">
            <div className="col-6">
              <div
                onClick={() => setActiveTab("wallet")}
                className={`PaymentMethod ${
                  activeTab === "wallet" && "active"
                }`}
              >
                {t("Wallet")}
              </div>
            </div>
            <div className="col-6">
              <div
                onClick={() => setActiveTab("stripe")}
                className={`PaymentMethod ${
                  activeTab === "stripe" && "active"
                }`}
              >
                {t("Visa")}
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex gap-2 dataRowSub">
              <label>{t("package Name")}</label>: {ShowModal?.title}
            </div>
            <div className="d-flex gap-2 dataRowSub">
              <label>{t("package Price")}</label>: {price}
            </div>
          </div>

          <button
            onClick={handleSubscribe}
            disabled={loading}
            className="submit-upload w-100 btn-main"
          >
            {loading ? <span className="loader"></span> : t("SubscribeNow")}
          </button>
        </div>
      </CustomModal>
      {paymentsuccessLottiex ? (
        <div className={styles.paymentSuccessPopUp}>
          <PaymentSuccessLottie />
        </div>
      ) : null}
    </>
  );
};

export default ExtraSubscriptionModal;
