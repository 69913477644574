import React from "react";
import paymentsuccess from "../../../assets/paymentSuccess.json";
import { useLottie } from "lottie-react";
function PaymentSuccessLottie() {
  const options = {
    animationData: paymentsuccess,
    loop: true,
    style: { width: "300px", height: "300px" },
  };
  const { View } = useLottie(options);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {View}
    </div>
  );
}

export default PaymentSuccessLottie;
